import React from "react";
import { Helmet } from "react-helmet";

export default ({ children, pageContext }) => {

  if (pageContext.layout === "2023") {
    return <>{children}</>;
  }

  return (
    <div>
      <Helmet>
        <script src="/counters/google-analytics.js" type="text/javascript"></script>
        <script src="/counters/google-tag-manager-head.js" type="text/javascript"></script>
        <script src="/counters/yandex-metrika.js" type="text/javascript"></script>
      </Helmet>
      <noscript><div><img src="https://mc.yandex.ru/watch/502212" style={{ position: 'absolute', left: '-9999px' }} alt="" /></div></noscript>
      {children}
    </div>
  );
};
