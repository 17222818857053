// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2020-js": () => import("./../../../src/pages/2020.js" /* webpackChunkName: "component---src-pages-2020-js" */),
  "component---src-pages-2021-js": () => import("./../../../src/pages/2021.js" /* webpackChunkName: "component---src-pages-2021-js" */),
  "component---src-pages-2023-js": () => import("./../../../src/pages/2023.js" /* webpackChunkName: "component---src-pages-2023-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

